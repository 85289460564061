<template>
  <div class="container">
    <h2>圆角查看器</h2>

    <el-row :gutter="12">
      <el-col :span="16">
        <div class="slider-wrapper">
          <span>style</span>
          <el-radio-group v-model="style">
            <el-radio label="px">px</el-radio>
            <el-radio label="%">%</el-radio>
          </el-radio-group>
        </div>
        <div class="slider-wrapper">
          <span>radius</span>
          <div class="radius-inputs">
            <el-input v-for="i in 4"
                      :key="i"
                      type="number"
                      :min="0"
                      :max="100"
                      v-model="radius1[i - 1]"></el-input>
            <span> / </span>
            <el-input v-for="i in 4"
                      :key="i + 4"
                      type="number"
                      :min="0"
                      :max="100"
                      v-model="radius2[i - 1]"></el-input>
          </div>
        </div>
        <div class="slider-wrapper">
          <span>value</span>
          <div>{{value}}</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="border-wrapper">
          <div ref="borderBox"
               class="border-box"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

export default {
  setup () {
    const borderBox = ref(null)
    const style = ref('px')
    const radius1 = ref([10, 20, 30, 40])
    const radius2 = ref([50, 60, 70, 80])
    const value = ref('')

    onMounted(() => {
      // borderBox.value.style['border-radius'] = `${radius.value}px`
      calculateValue()
    })
    watch([radius1, radius2, style], () => {
      // borderBox.value.style['border-radius'] = `${radius.value}px`
      calculateValue()
    }, { deep: true })
    const calculateValue = () => {
      value.value = ''
      if (radius1.value[0] !== '0') {
        for (let i = 0; i < radius1.value.length; i++) {
          const r = parseInt(radius1.value[i])
          if (r) {
            value.value += `${r}${style.value} `
          } else {
            break
          }
        }
      } else {
        value.value = '0'
        borderBox.value.style['border-radius'] = value.value
        return
      }

      if (radius2.value[0] !== '0') {
        value.value += ' / '
        for (let i = 0; i < radius2.value.length; i++) {
          const r = parseInt(radius2.value[i])
          if (r) {
            value.value += `${r}${style.value} `
          } else {
            break
          }
        }
      }

      borderBox.value.style['border-radius'] = value.value
    }

    return {
      radius1,
      radius2,
      style,
      value,
      borderBox
    }
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
}

.border-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.border-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 80px;
  transform: translate(-50%, -50%);
  border: solid 2px rgba(19, 206, 102, 0.8);
}

.slider-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.slider-wrapper > span {
  display: inline-block;
  width: 50px;
}

.slider-wrapper .radius-inputs {
  flex: 1;
  display: flex;
  align-items: center;
}

.slider-wrapper .radius-inputs .el-input {
  display: inline-block;
  margin: 0 2px;
}
</style>
