<template>
  <div class="container">
    <h2>颜色查看器</h2>

    <el-row :gutter="12">
      <el-col :span="16">
        <el-input placeholder="请输入内容"
                  v-model="colorHex"
                  @input="handleColorHexChange">
          <template #prepend>rrggbb</template>
        </el-input>
        <br /><br />
        <el-input placeholder="请输入内容"
                  v-model="colorRgb"
                  @input="handleColorRgbChange">
          <template #prepend>(r, g, b)</template>
        </el-input>
        <br /><br />
        <el-color-picker v-model="colorSelector"
                         @change="handleColorSelectorChange"></el-color-picker>
      </el-col>
      <el-col :span="8">
        <div ref="colorBox"
             class="color-box"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
  setup () {
    const colorBox = ref(null)
    const colorHex = ref('#000000')
    const colorRgb = ref('(0, 0, 0)')
    const colorSelector = ref('#000000')

    onMounted(() => {
      colorBox.value.style.background = colorHex.value
    })

    const handleColorHexChange = (val) => {
      colorSelector.value = val
      colorRgb.value = hex2grb(colorHex.value)
      colorBox.value.style.background = colorHex.value
    }
    const handleColorRgbChange = (val) => {
      colorRgb.value = val
      colorSelector.value = rgb2hex(colorRgb.value)
      colorHex.value = colorSelector.value
    }
    const handleColorSelectorChange = (val) => {
      colorHex.value = val
      colorRgb.value = hex2grb(colorHex.value)
      colorBox.value.style.background = colorHex.value
    }

    const hex2grb = (colorString) => {
      if (colorString.length === 4) {
        const r = colorString.substring(1, 2)
        const g = colorString.substring(2, 3)
        const b = colorString.substring(3, 4)
        const R = Number('0x' + r)
        const G = Number('0x' + g)
        const B = Number('0x' + b)
        return `(${R}, ${G}, ${B})`
      } else if (colorString.length === 7) {
        const r = colorString.substring(1, 3)
        const g = colorString.substring(3, 5)
        const b = colorString.substring(5, 7)
        const R = Number('0x' + r)
        const G = Number('0x' + g)
        const B = Number('0x' + b)
        return `(${R}, ${G}, ${B})`
      } else {
        return ''
      }
    }
    const rgb2hex = (colorString) => {
      const rgbString = colorString.replace(/\(|\)/g, '').replace(/ /g, '')
      console.log(rgbString)
      const rgbArray = rgbString.split(',')
      const r = Number(rgbArray[0])
      const g = Number(rgbArray[1])
      const b = Number(rgbArray[2])
      const R = r < 16 ? '0' + r.toString(16) : r.toString(16)
      const G = g < 16 ? '0' + g.toString(16) : g.toString(16)
      const B = b < 16 ? '0' + b.toString(16) : b.toString(16)
      return `#${R}${G}${B}`
    }

    return {
      colorHex,
      colorRgb,
      colorSelector,
      colorBox,
      handleColorHexChange,
      handleColorRgbChange,
      handleColorSelectorChange
    }
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
}

.color-box {
  width: 100%;
  height: 100%;
}
</style>
