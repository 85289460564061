<template>
  <div class="photo">
    <div v-for="photo in photoList"
         :key="photo.createAt">
      <p>{{photo.createAt}}</p>
      <p>{{photo.content}}</p>
      <div class="images">
        <el-image v-for="image in photo.imageList"
                  :key="image"
                  class="image"
                  :src="image"
                  fit="cover"
                  :preview-src-list="photo.imageList" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/runtime-core'

export default {
  setup () {
    const photoList = ref([])
    onMounted(async () => {
      for (let i = 0; i < 4; i++) {
        photoList.value.push({
          createAt: 312424234,
          content: '今天心情很好',
          imageList: [
            'http://localhost:8000/images/1624284940759_jieping.png',
            'http://localhost:8000/images/1624285581478_zhuye.png',
            'http://localhost:8000/images/1624284940759_jieping.png',
            'http://localhost:8000/images/1624285581478_zhuye.png',
            'http://localhost:8000/images/1624284940759_jieping.png',
            'http://localhost:8000/images/1624285581478_zhuye.png',
            'http://localhost:8000/images/1624284940759_jieping.png',
            'http://localhost:8000/images/1624285581478_zhuye.png',
            'http://localhost:8000/images/1624284940759_jieping.png'
          ]
        })
      }
    })

    return {
      photoList
    }
  }
}
</script>

<style lang="less" scoped>
.photo {
  max-width: 880px;
  margin: 0 auto;
}

.images {
  display: flex;
  flex-wrap: wrap;

  .image {
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
  }

  .image:not(:nth-child(5n)) {
    margin-right: 20px;
  }
}
</style>
