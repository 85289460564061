<template>
  <div class="container">
    <h2>阴影查看器</h2>

    <el-row :gutter="12">
      <el-col :span="16">
        <div class="slider-wrapper">
          <span class="demonstration">x</span>
          <el-slider v-model="x"
                     :min="0"
                     :max="40"></el-slider>
          <span>{{x}}</span>
        </div>
        <div class="slider-wrapper">
          <span class="demonstration"
                min="0"
                max="40">y</span>
          <el-slider v-model="y"
                     :min="0"
                     :max="40"></el-slider>
          <span>{{y}}</span>
        </div>
        <div class="slider-wrapper">
          <span class="demonstration"
                min="0"
                max="40">r1</span>
          <el-slider v-model="r1"
                     :min="0"
                     :max="40"></el-slider>
          <span>{{r1}}</span>
        </div>
        <div class="slider-wrapper">
          <span class="demonstration"
                min="0"
                max="40">r2</span>
          <el-slider v-model="r2"
                     :min="0"
                     :max="40"></el-slider>
          <span>{{r2}}</span>
        </div>
        <div class="slider-wrapper">
          <span class="demonstration">color</span>
          <el-color-picker v-model="colorSelector"
                           show-alpha
                           @change="handleColorSelectorChange"></el-color-picker>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="shadow-wrapper">
          <div ref="shadowBox"
               class="shadow-box"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

export default {
  setup () {
    const shadowBox = ref(null)
    const x = ref(0)
    const y = ref(0)
    const r1 = ref(20)
    const r2 = ref(10)
    const colorSelector = ref('rgba(19, 206, 102, 0.8)')

    onMounted(() => {
      shadowBox.value.style['box-shadow'] = `${x.value}px ${y.value}px ${r1.value}px ${r2.value}px ${colorSelector.value}`
    })
    watch([x, y, r1, r2, colorSelector], () => {
      shadowBox.value.style['box-shadow'] = `${x.value}px ${y.value}px ${r1.value}px ${r2.value}px ${colorSelector.value}`
    })

    return {
      x,
      y,
      r1,
      r2,
      colorSelector,
      shadowBox
    }
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
}

.shadow-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.shadow-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 80px;
  transform: translate(-50%, -50%);
  background: #aaaaaa;
}

.slider-wrapper {
  display: flex;
  align-items: center;
}

.slider-wrapper > span {
  display: inline-block;
  width: 50px;
}

.slider-wrapper .el-slider {
  flex: 1;
  margin-left: 20px;
}
</style>
