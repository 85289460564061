<template>
  <div class="container">
    <div class="component-box">
      <color-preview />
    </div>
    <div class="component-box">
      <shadow-preview />
    </div>
    <div class="component-box">
      <border-preview />
    </div>
    <div class="component-box">
      <border-radius-preview />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import BorderPreview from './BorderPreview.vue'
import BorderRadiusPreview from './BorderRadiusPreview.vue'
import ColorPreview from './ColorPreview.vue'
import ShadowPreview from './ShadowPreview.vue'

export default {
  components: {
    ColorPreview,
    ShadowPreview,
    BorderPreview,
    BorderRadiusPreview
  },
  setup () {
    const store = useStore()
    const articles = store.state.articles

    const router = useRouter()
    const toArticle = (article) => {
      router.push({ name: 'Article', query: { id: article.id } })
    }

    return {
      articles,
      toArticle
    }
  }
}
</script>

<style scoped>
.container {
  overflow-y: scroll;
  margin: 0 auto;
  text-align: start;
}
.component-box {
  max-width: 880px;
  margin: 20px auto;
  padding: 0 24px 20px 24px;
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
</style>
