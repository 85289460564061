<template>
  <div class="container">
    <h2>边框查看器</h2>

    <el-row :gutter="12">
      <el-col :span="16">
        <div class="slider-wrapper">
          <span>width</span>
          <el-slider v-model="width"
                     :min="0"
                     :max="10"></el-slider>
          <span>{{width}}</span>
        </div>
        <div class="slider-wrapper">
          <span>radius</span>
          <el-slider v-model="radius"
                     :min="0"
                     :max="20"></el-slider>
          <span>{{radius}}</span>
        </div>
        <div class="slider-wrapper">
          <span>style</span>
          <el-radio-group v-model="style">
            <el-radio label="none">none</el-radio>
            <el-radio label="hidden">hidden</el-radio>
            <el-radio label="dotted">dotted</el-radio>
            <el-radio label="dashed">dashed</el-radio>
            <el-radio label="solid">solid</el-radio>
            <el-radio label="double">double</el-radio>
            <el-radio label="groove">groove</el-radio>
            <el-radio label="ridge">ridge</el-radio>
            <el-radio label="inset">inset</el-radio>
            <el-radio label="outset">outset</el-radio>
          </el-radio-group>
        </div>
        <div class="slider-wrapper">
          <span>color</span>
          <el-color-picker v-model="colorSelector"
                           show-alpha></el-color-picker>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="border-wrapper">
          <div ref="borderBox"
               class="border-box"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

export default {
  setup () {
    const borderBox = ref(null)
    const width = ref(2)
    const radius = ref(4)
    const style = ref('solid')
    const colorSelector = ref('rgba(19, 206, 102, 0.8)')

    onMounted(() => {
      borderBox.value.style.border = `${style.value} ${width.value}px ${colorSelector.value}`
      borderBox.value.style['border-radius'] = `${radius.value}px`
    })
    watch([width, radius, style, colorSelector], () => {
      borderBox.value.style.border = `${style.value} ${width.value}px ${colorSelector.value}`
      borderBox.value.style['border-radius'] = `${radius.value}px`
    })

    return {
      width,
      radius,
      style,
      colorSelector,
      borderBox
    }
  }
}
</script>

<style scoped>
.container {
  /* width: 880px; */
  margin: 0 auto;
}

.border-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.border-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 80px;
  transform: translate(-50%, -50%);
}

.slider-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.slider-wrapper > span {
  display: inline-block;
  width: 50px;
}

.slider-wrapper .el-radio-group,
.slider-wrapper .el-slider {
  flex: 1;
}
</style>
